import {createRouter, createWebHistory} from 'vue-router';

import HomePage from '@/views/HomePage';

let locale = window.location.pathname.replace(/^\/([^/]+).*/i, '$1');
if (locale === 'ru') {
    locale = locale ? '/' + locale : '';
} else {
    locale = '';
}
export default new createRouter({
    history: createWebHistory(),
    base: (locale.trim().length && locale !== "/") ? '/' + locale : undefined,
    routes: [
        {
            path: locale + '/',
            component: HomePage,
        },
        {
            path: locale + '/info',
            component: () => import('./views/InfoPage.vue'),
        },
        {
            path: locale + '/pub',
            component: () => import('./views/PublicationPage.vue'),
        },
  /*      {
            path: locale + '/project',
            component: () => import('./views/ProjectPage.vue'),
        },*/
        {
            path: locale + '/service',
            component: () => import('./views/ServicePage.vue'),
        },
        {
            path: '/:catchAll(.*)',
            component: () => import('./views/NotFound.vue'),
        },
    ]
})

<template>
  <nav v-if="!!menuList">
    <router-link
        v-for="menu of menuList"
        v-bind:key="menu.id"
        v-bind:to="menu.url"
        v-bind:class="[menu.url === currentPage.path ? 'active' : '', menu.icon ? 'icon fa-' + menu.icon : '']"
    >
      <span>{{ menu.title }}</span>
    </router-link>
  </nav>
</template>

<script>

export default {
  name: 'TopMenu',
  components: {},
  props: [
    'menuList',
    'currentPage',
  ]
}
</script>

<style scoped>
a {
  position: relative;
  display: inline-block;
  color: #fff;
  width: 1em;
  height: 1em;
  line-height: 0.9em;
}

a.icon:before {
  padding-right: 0;
}

a.active {
  opacity: 1.0;
}

a.active:after {
  border-bottom-width: 0.5em;
}

@media screen and (min-width: 737px) {
  a {
    font-size: 2.5em;
    margin: 0 0.25em 0 0.25em;
    opacity: 0.35;
    outline: 0;
    -moz-transition: opacity .25s ease-in-out;
    -webkit-transition: opacity .25s ease-in-out;
    -ms-transition: opacity .25s ease-in-out;
    transition: opacity .25s ease-in-out;
  }

  a:before {
    font-size: 0.8em;
  }

  a:after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    bottom: -0.75em;
    margin-left: -0.5em;
    border-bottom: solid 0em #fff;
    border-left: solid 0.5em transparent;
    border-right: solid 0.5em transparent;
    -moz-transition: border-bottom-width .25s ease-in-out;
    -webkit-transition: border-bottom-width .25s ease-in-out;
    -ms-transition: border-bottom-width .25s ease-in-out;
    transition: border-bottom-width .25s ease-in-out;
  }

  a span {
    display: block;
    position: absolute;
    background: #222;
    color: #fff;
    top: -2.75em;
    font-size: 0.3em;
    height: 2.25em;
    line-height: 2.25em;
    left: 50%;
    opacity: 0;
    -moz-transition: opacity .25s ease-in-out;
    -webkit-transition: opacity .25s ease-in-out;
    -ms-transition: opacity .25s ease-in-out;
    transition: opacity .25s ease-in-out;
    width: 5.5em;
    margin-left: -2.75em;
  }

  a span:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -0.4em;
    left: 50%;
    margin-left: -0.6em;
    border-top: solid 0.6em #222;
    border-left: solid 0.6em transparent;
    border-right: solid 0.6em transparent;
  }

  a:hover {
    opacity: 1.0;
  }

  a:hover span {
    opacity: 1.0;
  }


}

@media screen and (max-width: 736px) {
  a {
    font-size: 2em;
    opacity: 0.5;
    outline: 0;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.35em;
    margin: 0 0.25em 0 0.25em;
  }

  a:active {
    opacity: 1.0;
  }

  a span {
    display: none;
  }
}

@media screen and (min-width: 737px) {
  nav {
    text-align: center;
    height: 4.25em;
    cursor: default;
  }
}

@media screen and (max-width: 736px) {
  nav {
    text-align: center;
    margin: 6px 0 8px 0;
  }
}
</style>

<template>
  <div v-if="!!Items">
    <a
        v-for="item of Items"
        v-bind:key="item.id"
        v-bind:class="[item.icon ? 'icon fa-' + item.icon : '']"
        v-bind:title="item.title"
        v-bind:href="item.href"
        target="_blank"
    ></a>

  </div>
</template>

<script>
export default {
  name: "LinksIcon",
  props: {
    Items: {
      required: true,
    },
  }
}
</script>

<style scoped>
a {
  color: rgba(54, 54, 54, 0.76);
  font-size: 30px;
  margin-right: 10px;
}
.fa-telegram {
  font-size: 28px;
  color: #363636;
}
</style>